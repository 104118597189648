<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header flex gap-2 flex-col">
          <div class="flex w-full flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
            <div class="input-group input-group-flush d-flex flex-row-reverse flex-grow">
              <input
                v-model.trim="search"
                class="form-control list-search"
                type="search"
                placeholder="Search"
                />
              <span class="input-group-text border-0">
                <i
                  class="fe fe-search"
                  @click.prevent="fetchNewPartners(true)"
                  ></i>
              </span>
            </div>
            <date-picker :range="true" class="shrink-0 sm:max-w-[220px]" v-model="date" valueType="format" placeholder="Filter by created date"></date-picker>
          </div>
				</div>

				<div class="flex flex-col sm:flex-row sm:items-center w-full sm:justify-between">
					<div class="p-3">
						<label for="status_active" class="d-block-inline px-2">
							<input
								type="radio"
								v-model="statusFilter"
								name="status"
								value="active"
								id="status_active"
								/>
							Active
						</label>
						<label for="status_inactive" class="d-block-inline px-2">
							<input
								type="radio"
								v-model="statusFilter"
								name="status"
								value="inactive"
								id="status_inactive"
								/>
							Inactive
						</label>
					</div>
          <DownloadButton class="!shrink-0" :downloading="downloadingReport" label="Download Report" @download="downloadReport()" />
				</div>

				<b-table
					striped
					hover
					selectable
					responsive
					show-empty
					:items="partners"
					:fields="fields"
					:current-page="currentPage"
					:busy="fetchingPartners"
					@row-clicked="showPartnerDetails"
					>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template #empty>
						<p class="text-center text-secondary">
							{{
								search
									? `No records found for search value: "${search}"`
									: 'No records available'
							}}
						</p>
					</template>
					<template #cell(name)="data">
						<div class="d-flex align-items-center">
							<user-avatar :user="data.item" size="sm"></user-avatar>
							<p class="ml-2">
								{{ `${data.item.owner.fname} ${data.item.owner.lname}` }}
							</p>
						</div>
					</template>
					<template #cell(active)="data">
						<span
							class="text-white"
							:class="`badge p-2 ${
								data.item.status === 'active'
									? 'badge-success'
									: data.item.status === 'suspended'
										? 'badge-danger'
										: data.item.status === 'pending'
											? 'badge-warning'
											: ''
							}`"
							>
							{{ data.item.status }}
						</span>
					</template>
					<template #cell(serialNumber)="data">
						<p>
							{{ data.index + 1 }}
						</p>
					</template>
					<template #cell(company_name)="data">
						{{ data.item.company_name }}
					</template>
					<template #cell(email)="data">
						{{ data.item.company_email }}
					</template>
					<template #cell(vehicle_count)="data">
						{{ data.item.vehicles_count }}
					</template>
					<template #cell(created_at)="data">
						{{ data.value | date('MMMM d, yyyy') }}
					</template>

					<!--          <template #cell(actions)>-->
					<!--          </template>-->
				</b-table>

				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div>
						<div class="col-auto">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="pageSize"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserAvatar from '@/components/core/UserAvatar.vue'
import moment from 'moment'
import TableView from '@/mixins/tableview'
import DownloadButton from '@/components/core/DownloadButton.vue'
import Papa from 'papaparse'
import { axiosInstance as axios } from '@/plugins/axios.js'
import { downloadFile } from '@/composables/utils'
import DatePicker from 'vue2-datepicker'
import {addToQuery, removeQueryParam} from '@/composables/utils'
import router from '@/router'
import Swal from 'sweetalert2'

export default {
  mixins: [TableView],
  components: {
    UserAvatar, DownloadButton, DatePicker
  },
  data () {
    return {
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'company_name',
          label: 'Company Name'
        },
        {
          key: 'email',
          label: 'Email'
        },
        // {
        //   key: 'phone',
        //   label: 'Phone',
        // },
        // {
        //   key: 'vehicle_type',
        //   label: 'Vehicle Type',
        // },
        // {
        //   key: 'vehicle_brand',
        //   label: 'Vehicle Brand',
        // },
        {
          key: 'vehicle_count',
          label: 'Number of Vehicle'
        },
        {
          key: 'created_at',
          label: 'Created At'
        },
        {
          key: 'active',
          label: 'Active'
        }
        /*        {
          key: 'actions',
          label: 'Actions',
        }, */
      ],
      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      partners: [],
      fetchingPartners: true,
      search: '',
      debounce: null,
      filter: {
        range: []
      },
      statusFilter: 'active',
      date: [],
      downloadingReport: false
    }
  },
  computed: {
  },
  methods: {
    fetchNewPartners (reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingPartners = true
      const url = `/v1/partners?metadata=true&limit=${this.pageSize}&page=${
        this.currentPage
      }&new_signup=1&status=${this.statusFilter}&related=owner${
        this.search ? `&search=${this.search}` : ''
      }${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`
      const request = this.axios.get(url)
      request
        .then((res) => {
          this.partners = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.partners
        })
        .finally(() => (this.fetchingPartners = false))
    },
    async downloadReport() {
      this.downloadingReport = true
      try {
        const res = await axios.get(`/v1/partners?metadata=true&limit=${this.pageSize}&page=1&new_signup=1&status=${this.statusFilter}&related=owner${this.search ? `&search=${this.search}` : ''}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`)
        if(res.data?.data?.length){
          const total = res.data?.metadata?.total
          const resp = await axios.get(`/v1/partners?metadata=true&limit=${total}&page=1&new_signup=1&status=${this.statusFilter}&related=owner${this.search ? `&search=${this.search}` : ''}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`)
          const x = resp.data?.data

          const newArr = []
          for (const el of x) {
            const y = {
              Name: `${el?.owner?.fname || '' } ${el?.owner?.lname || ''}`,
              Company_name: el?.company_name || '',
              Email: el?.owner?.email || 'N/A',
              Number_of_vehicles: el?.vehicles_count || 'N/A',
              Date_created: moment(el?.created_at).format('MMMM Do, YYYY') || 'N/A',
              Status: el?.status,
            }
            newArr.push(y)
          }

          const csv = Papa.unparse(newArr)
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
          const url = URL.createObjectURL(blob)
          downloadFile(url, `all-${this.statusFilter}-new-partners`)
          this.downloadingReport = false
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: 'No data to download'})
          this.downloadingReport = false
        }
      } catch (err) {
        Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message || 'No data to download'})
        this.downloadingReport = false
      }
    },
    checkForFilters() {
      const q = this.$route.query
      if (q.search) this.search = q.search
      if(q.from && q.to) {
        this.date = [q.from, q.to]
      }
      this.fetchNewPartners()
    },

    showPartnerDetails (data) {
      this.$router.push({
        name: 'PartnerDetails',
        params: {
          vehicleCount: data.vehicles_count,
          partnerId: data.id,
          accountSid: data.account_sid
        }
      })
    }
  },
  watch: {
    
    search () {
      // clearTimeout(this.debounce)
      // this.debounce = setTimeout(() => {
      //   this.fetchNewPartners(true)
      // }, 600)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if(this.search) {
          addToQuery(this.$route, router, {search: this.search})
        } else {
          removeQueryParam(this.$route, router, ['search'])
        }
        this.fetchNewPartners(true)
      }, 600)
    },
    currentPage () {
      this.fetchNewPartners()
    },
    statusFilter () {
      this.fetchNewPartners(true)
    },
    date () {
      if(this.date[0] && this.date[1]) {
        addToQuery(this.$route, router, {from: this.date[0], to: this.date[1]})
      } else {
        removeQueryParam(this.$route, router, ['from', 'to'])
      }
      this.fetchNewPartners(true)
    },
  },
  mounted() {
    this.checkForFilters()
  }
}
</script>

<style scoped lang="scss">
@use 'src/assets/scss/b-table';
</style>
